import React, {useState} from 'react';
import {Button, Confirm, Icon, Popup} from 'semantic-ui-react';
import {FormattedMessage, useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {MODULE_TYPES} from '@ecosio/auth';
import {wrappedPOST} from '../../../helpers/fetcher';
import 'regenerator-runtime/runtime';

const mayReopenIssue = (issue, configuration, isOfferingCompany) => {
  if (!issue || !configuration || !issue.issueType) {
    return false;
  }

  const issueTypes =
    configuration[MODULE_TYPES.QUALITY_ASSURANCE]?.configuration
      ?.supportedIssueTypes;
  const issueType = issueTypes?.find((c) => c.issueType === issue.issueType);

  if (!issueType) {
    return false;
  }

  return isOfferingCompany
    ? issueType.offeringCompanyMayReopenIssues
    : issueType.usingCompanyMayReopenIssues;
};

const ReopenIssueButton = ({
  issue,
  onReopenSuccess,
  onReopenError,
  isOfferingCompany,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const configuration = useSelector(
    (state) => state.supplyConfiguration?.supplyModules
  );
  const mayReopen = mayReopenIssue(issue, configuration, isOfferingCompany);
  const intl = useIntl();

  if (!mayReopen) {
    return null;
  }

  const onReopenIssue = async () => {
    setLoading(true);
    try {
      const {issueNumber} = issue;
      const response = await wrappedPOST(`/api/issues/${issueNumber}/reopen`);
      onReopenSuccess && onReopenSuccess(response.data);
    } catch (err) {
      onReopenError && onReopenError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Confirm
        content={intl.formatMessage({
          id: 'GENERAL_CONFIRM_MESSAGE',
        })}
        cancelButton={intl.formatMessage({
          id: 'GENERAL_CANCEL',
        })}
        confirmButton={intl.formatMessage({
          id: 'GENERAL_OK',
        })}
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={onReopenIssue}
      />

      <Popup
        trigger={
          <Button
            negative
            onClick={() => setConfirmOpen(true)}
            disabled={loading}
            loading={loading}
            icon>
            <Icon name="undo" /> <FormattedMessage id="REOPEN_ISSUE_BUTTON" />
          </Button>
        }
        content={<FormattedMessage id="REOPEN_ISSUE_TOOLTIP" />}
      />
    </>
  );
};

ReopenIssueButton.propTypes = {
  issue: PropTypes.object.isRequired,
  isOfferingCompany: PropTypes.bool.isRequired,
  onReopenSuccess: PropTypes.func.isRequired,
  onReopenError: PropTypes.func.isRequired,
};

export default ReopenIssueButton;
