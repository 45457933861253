import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import initServices from './helpers/initServices';

initServices();

const root = createRoot(document.getElementById('root'));
root.render(<App />);

if (module.hot) {
  module.hot.accept();
}
