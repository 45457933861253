import React from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Header,
  Confirm,
  Message,
  Divider,
  Label,
  Grid,
  List,
} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {
  updateSupplierStatus,
  postSupplier,
} from '../../../reducers/supplierExchange';
import {fetchCategories} from '../../../reducers/categories';
import {colorForAssessmentStatus} from '../../SupplierDB/SupplierList';
import {NotSpecified} from '../../FormHelpers';
import {convertToFormData} from '../../../util';
import UsingCompanyHeader from './UsingCompanyHeader';
import OfferingCompanyHeader from './OfferingCompanyHeader';

const ListItem = ({content, labelId}) => {
  return (
    <List.Item divided="true">
      <List.Content floated="right">{content || <NotSpecified />}</List.Content>
      <List.Content>
        <strong>
          <FormattedMessage id={labelId} />
        </strong>
      </List.Content>
    </List.Item>
  );
};

const OfferingCompanyProperties = ({supplier, categories, locale}) => {
  if (!categories) {
    return null;
  }

  const internalSupplierId =
    supplier.internalSupplierIds.length > 0 && supplier.internalSupplierIds[0];

  const preCheckState = `PRE_CHECK_${supplier.preCheckState}`;

  const assignedCategory = categories.find(
    (c) => c.uuid === supplier.assignedCategoryId
  );
  const testRequestDone = supplier.testRequestDone
    ? 'GENERAL_YES'
    : 'GENERAL_NO';
  return (
    <List>
      <ListItem
        content={assignedCategory && assignedCategory.name[locale]}
        labelId="ASSIGNED_CATEGORY_ID"
      />
      <ListItem
        content={<FormattedMessage id={preCheckState} />}
        labelId="PRE_CHECK_STATE"
      />
      <ListItem content={internalSupplierId} labelId="INTERNAL_SUPPLIER_ID" />
      <ListItem
        content={<FormattedMessage id={testRequestDone} />}
        labelId="TEST_REQUEST_DONE"
      />
      <ListItem content={supplier.updatedBy} labelId="UPDATED_BY" />
    </List>
  );
};

const mapStateToProps = ({supplierExchange, locales, categories}) => ({
  supplier: supplierExchange.supplier,
  updating: supplierExchange.updating,
  error: supplierExchange.error,
  locale: locales.locale,
  categories,
});

@injectIntl
@connect(mapStateToProps, {
  updateSupplierStatus,
  postSupplier,
  fetchCategories,
})
export default class PageHeader extends React.Component {
  static propTypes = {
    assessmentState: PropTypes.string.isRequired,
    offeringCompany: PropTypes.bool.isRequired,
    supplier: PropTypes.any.isRequired,
    updating: PropTypes.bool,
    error: PropTypes.string,
    updateSupplierStatus: PropTypes.func,
    formSubmit: PropTypes.func,
  };

  state = {
    confirmOpen: false,
    confirmCallback: null,
  };

  updateSupplierState = (
    newState,
    params = {},
    supplierForm,
    withPost = true
  ) => {
    this.props
      .updateSupplierStatus(this.props.supplier.uuid, newState, params)
      .then((savedSupplier) => {
        if (withPost) {
          const supplier = supplierForm && supplierForm.values;
          if (supplier) {
            supplier.onboardingStatus = savedSupplier.onboardingStatus;
            supplier.status = savedSupplier.status;
            const formData = convertToFormData(supplier);
            this.props.postSupplier(formData);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  openModal = (callback) => {
    this.setState({
      confirmOpen: true,
      confirmCallback: callback,
    });
  };

  closeModal = () => {
    this.setState({
      confirmOpen: false,
    });
  };

  onConfirm = () => {
    this.closeModal();
    if (this.state.confirmCallback) {
      this.state.confirmCallback();
    }
  };

  render() {
    const {
      offeringCompany,
      supplier,
      updating,
      intl,
      notification,
      hideNotification,
      setNotification,
      locale,
      categories,
      error,
    } = this.props;

    const postponeDate =
      offeringCompany && supplier.nextAssessment
        ? `(${intl.formatDate(supplier.nextAssessment)})`
        : '';

    const errorNotification = error && {
      negative: true,
      header: <FormattedMessage id="GENERAL_SUBMIT_ERROR_HEADER" />,
      body: <FormattedMessage id="GENERAL_SUBMIT_ERROR_MSG" />,
    };

    const notificationObj = errorNotification || notification;

    return (
      <React.Fragment>
        {notificationObj && (
          <Message
            onDismiss={hideNotification}
            warning={notificationObj.warning}
            negative={notificationObj.negative}
            positive={notificationObj.positive}
            info={notificationObj.info}>
            <Message.Header>{notificationObj.header}</Message.Header>
            <p>{notificationObj.body}</p>
          </Message>
        )}
        <Segment
          color="purple"
          loading={updating || categories.loading}
          style={{marginTop: '0'}}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={8}>
                <Header size="large" as="h1">
                  <FormattedMessage id="GENERAL_ACTIONS" />
                </Header>
                <Label color={colorForAssessmentStatus(supplier.status)}>
                  <FormattedMessage
                    id={supplier.status}
                    values={{postponeDate}}
                  />
                </Label>
              </Grid.Column>

              {offeringCompany && (
                <Grid.Column widescreen={4} computer={8} mobile={8} tablet={8}>
                  <OfferingCompanyProperties
                    categories={categories.data}
                    supplier={supplier}
                    locale={locale}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>

          <Confirm
            open={this.state.confirmOpen}
            onCancel={this.closeModal}
            onConfirm={this.onConfirm}
            content={intl.formatMessage({
              id: 'GENERAL_CONFIRM_MESSAGE',
            })}
            cancelButton={intl.formatMessage({
              id: 'GENERAL_CANCEL',
            })}
            confirmButton={intl.formatMessage({
              id: 'GENERAL_OK',
            })}
          />

          <Divider />

          {offeringCompany ? (
            <OfferingCompanyHeader
              openModal={this.openModal}
              updateSupplierState={this.updateSupplierState}
              setNotification={setNotification}
              {...this.props}
            />
          ) : (
            <UsingCompanyHeader
              setNotification={setNotification}
              openModal={this.openModal}
              {...this.props}
            />
          )}
        </Segment>
      </React.Fragment>
    );
  }
}
