import React from 'react';
import axios from 'axios';
import {Provider} from 'react-redux';
import {ConnectedIntlProvider} from '@ecosio/locales';
import {ErrorBoundary} from '@ecosio/components';
import {
  configureAxios,
  axiosParamsSerializer,
  setupAxiosInterceptors as setupAxios,
  errorHandler,
  setupAxiosDefaults,
} from '@ecosio/auth';
import configureStore, {history} from './store';
import AppRouter from './AppRouter';

const allowedPaths = [];

setupAxios(store, allowedPaths);
setupAxiosDefaults(axios);
axios.interceptors.response.use(
  (response) => response,
  errorHandler(store, allowedPaths)
);

/**
 * ☢️ WARNING ☢️
 * DO NOT export this to get the context path of the app, use
 * the CONTEXT_PATH constant in constants.js
 */
const BASE_URL = '/supply';

axios.defaults.paramsSerializer = axiosParamsSerializer;

configureAxios((authAxios) => {
  authAxios.defaults.baseURL = BASE_URL;
});

axios.defaults.baseURL = BASE_URL;

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <ConnectedIntlProvider>
      <ErrorBoundary exceptionLogger={console.error} axios={axios}>
        <AppRouter history={history} />
      </ErrorBoundary>
    </ConnectedIntlProvider>
  </Provider>
);

export default App;
